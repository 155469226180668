<template>
<div>
       <!-- <div v-if="isLogin">{{$t('messages.info.connect')}}</div>-->
        <!-- <router-view/>-->
        <router-view  v-slot="{ Component }">
            <keep-alive :include="includeList">
                <component :is="Component"></component>
            </keep-alive>
        </router-view>

</div>
</template>


<script setup>
  import { ref, watch, onMounted} from 'vue'
  import { useRoute } from 'vue-router'
  import { useStore } from 'vuex'



  const route = useRoute()
  const store = useStore()
  let isLogin = ref(false);


  const includeList = ref([])


  // 保持生命
  watch(() => route, (to) => {
     //监听路由变化，把配置路由中keepAlive为true的name添加到include动态数组中
      if(to.meta.keepAlive && includeList.value.indexOf(to.name) === -1){
          includeList.value.push(to.name);
      }
   }, { deep: true, immediate: true })

  onMounted(()=>{


      if(store.state.user.isLogin) {
          store.dispatch('updateUser');
      }

  })

</script>


<style lang="scss">

    @import "assets/css/style.css";


    @media (max-width: 960px) {
        .wrapper {
            position: absolute;
            top:15vw;
            bottom: 16vw;
            left:0;
            right:0;
            overflow: hidden;
            .content {
                height:auto;
            }

        }
    }

    @media (min-width: 961px) {
        .wrapper {
            width:1200px;
            height:auto;
            margin:0 auto;
            overflow: hidden;
            .content {
                padding-top:30px;
                width:94%;
                margin: 0 auto;
                height:auto;
            }

        }
    }





</style>
