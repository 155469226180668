import {createRouter, createWebHistory, createWebHashHistory} from 'vue-router'
/* 首页页面 */
const Home = () => import('../views/home/Home');
const Profile = () => import('../views/profile/Profile');
const Register = () => import('../views/profile/Register');
const Login = () => import('../views/profile/Login');
const About = () => import('../views/about/About');
const Setting = () => import('../views/setting/Setting');
const Goods = () => import('../views/goods/Goods');
const Order = () => import('../views/order/Order');

import store from '../store';
import {showNotify} from "vant";


const routes = [
      /* 首页页面 */
      {
        path: '/',
        alias:['/','/home'],
        name: 'Home',
        component: Home,
        meta:{
          title:'chatGPT',
          keepAlive:true,
          isAuthRequired:true,
          back:1,
        }
      },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
        meta:{
            title:'chatGPT',
            keepAlive:true,
            isAuthRequired:true,
            back:2,
        }
    },
    {
        path: '/register',
        name: 'Register',
        component: Register,
        meta:{
            title:'chatGPT',
            keepAlive:true,
            isAuthRequired:false,
            back:3,
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta:{
            title:'chatGPT',
            keepAlive:true,
            isAuthRequired:false,
            back:4,
        }
    },
    {
        path: '/about',
        name: 'About',
        component: About,
        meta:{
            title:'chatGPT',
            keepAlive:true,
            isAuthRequired:false,
            back:5,
        }
    },
    {
        path: '/setting',
        name: 'Setting',
        component: Setting,
        meta:{
            title:'chatGPT',
            keepAlive:true,
            isAuthRequired:true,
            back:6,
        }
    },
    {
        path: '/goods',
        name: 'Goods',
        component: Goods,
        meta:{
            title:'chatGPT',
            keepAlive:true,
            isAuthRequired:false,
            back:7,
        }
    },
    {
        path: '/order',
        name: 'Order',
        component: Order,
        meta:{
            title:'chatGPT',
            keepAlive:true,
            isAuthRequired:true,
            back:8,
        }
    },

]

const router = createRouter({
   // history: createWebHistory(process.env.BASE_URL),
    history:createWebHashHistory(),
    routes
})

router.beforeEach((to, from, next) => {


    if(to.meta.isAuthRequired && store.state.user.isLogin === false) {
      showNotify('请先登录')
        return next('/login')
    }else{
      next();
    }


    document.title = to.meta.title

})

export default router
