import {createApp, showNotify} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'vant/lib/index.css'


// 引入实体图标
import './assets/font/iconfont.css'

// 剪切板
import vueclipboard from 'vue-clipboard2'
vueclipboard.config.autoSetContainer = true




import {Col,Row,ConfigProvider,
    DatePicker,TimePicker,PickerGroup,Search,NoticeBar,
    Step, Steps,Overlay,Tabbar,DropdownMenu, DropdownItem,Picker,Calendar,
    TabbarItem, NavBar,PullRefresh,List,Popup,Grid,GridItem,AddressEdit,AddressList,
    Icon,SubmitBar,CheckboxGroup,SwipeCell,Stepper,Checkbox,Field,Form,Tag, Button,
    Image as VanImage, Card,Tab, Tabs, Swipe, SwipeItem,Lazyload,Badge,Sidebar,CountDown,
    SidebarItem, Collapse, CollapseItem,  Empty, Divider,  Uploader,Loading,Skeleton,
    RadioGroup, Radio, Cell, CellGroup, CouponCell, CouponList,ContactEdit,Dialog, Progress } from 'vant';

const Vue = createApp(App)
    .use(Swipe).use(SwipeItem)
    .use(Search).use(Badge).use(NoticeBar).use(Sidebar).use(SidebarItem).use(Collapse).use(CollapseItem)
    .use(Row).use(Col).use(Tab).use(Tabs).use(Card).use(VanImage).use(Tag).use(Button).use(Form).use(Field)
    .use(Checkbox).use(Stepper).use(SwipeCell).use(CheckboxGroup).use(SubmitBar).use(Icon).use(AddressList).use(AddressEdit)
    .use(GridItem).use(Grid).use(Popup).use(List).use(PullRefresh).use(Empty).use(Divider)
    .use(Uploader).use(Loading).use(Skeleton).use(RadioGroup).use(Radio).use(CellGroup)
    .use(Cell).use(CouponCell).use(CouponList).use(ContactEdit).use(Dialog)
    .use(NavBar).use(Tabbar).use(TabbarItem).use(DropdownMenu).use(DropdownItem)
    .use(Overlay).use(Step).use(Steps).use(NoticeBar).use(Progress).use(CountDown)
    .use(Calendar).use(Picker).use(PickerGroup).use(TimePicker).use(DatePicker).use(ConfigProvider)
    .use(vueclipboard)
    .use(store).use(router)

Vue.mount('#app')

