import { createStore } from 'vuex'
import {getUser} from 'network/user'

export default createStore({
  state: {

    user:{
      isLogin:window.localStorage.getItem('token') ? true : false
    },
    userinfo:{}
  },
  getters:{
    usenum(state){
        let num =  state.userinfo.total-state.userinfo.unum;

        return num < 10 ? '0'+num : num;
    }
  },
  mutations: {
    setUnum(state, payload) {
      state.userinfo.unum++;
    },
    setIsLogin(state, payload) {
      state.user.isLogin = payload;
    },
    setUser(state, payload){
      state.userinfo = payload;
    }
  },
  actions: {
    updateUser({state, commit}) {
      if(state.user.isLogin){
        getUser().then(res=>{
          commit('setUser', res.data)
        })
      }else{
        commit('setUser', {})
      }
    }
  },
  modules: {
  }
})
